.projectCard {
  width: 300px;
  height: 380px;
  background-color: #222;
  position: relative;
  border-radius: 0.325rem;
  background-size: cover;
  margin: 20px;
  overflow: hidden;
}

.projectDesc {
  transform: translateY(-3.725rem);
}

.projectDesc-hovered {
  transform: translateY(-100%);
}
