@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
}

* {
  scroll-behavior: smooth;
}
