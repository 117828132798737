.avatar {
  width: 200px;
  height: 385.5px;
  position: relative;
  user-select: none;
  margin: auto;
  grid-row: 1;
}

.avatar > img {
  position: absolute;
  width: 200px;
  bottom: 0;
}
.avatar > img:nth-child(1) {
  opacity: 30%;
  filter: blur(2px) grayscale(80%);
  transform-origin: bottom;
  transform: rotateZ(-10deg) scaleY(1.2) rotateX(30deg);
}
.avatar > img:nth-child(2) {
  opacity: 50%;
  filter: blur(2px) grayscale(80%);
  transform-origin: bottom;
  transform: rotateZ(10deg) scaleY(1.2) rotateX(30deg);
}

.orbit {
  position: absolute;
  bottom: 0px;
  width: 0px;
  left: calc(50% - 50px);
  height: 50px;

  perspective: 30em;
  perspective-origin: 0 -2em;
  overflow: visible;
}

.orbit > div {
  width: 50px;
  height: 50px;
  position: absolute;
  transform-style: preserve-3d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  to {
    transform: rotateY(360deg);
  }
}

@media (min-width: 768px) {
  .avatar {
    grid-column: 2;
    width: 300px;
  }
  .avatar > img {
    width: 300px;
  }
}
